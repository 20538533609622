import React from 'react';
import { Row, Col, Avatar, Input, List } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const { TextArea } = Input;

interface ConversationListItemProps {
  item: any;
  index: number;
  isEditing: boolean;
  handleInputChange: (index: number, language: string, text: string) => void;
  language: string;
}

const ConversationListItem: React.FC<ConversationListItemProps> = ({
  item,
  index,
  isEditing,
  handleInputChange,
  language,
}) => {
  return (
    <List.Item style={{ border: 0, padding: "1em" }}>
      {item.speaker === 0 ? (
        <Row justify="end" style={{ width: "100%" }}>
          <Col
            span={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar
              size={48}
              icon={<UserOutlined />}
              style={{
                backgroundColor: "#e6e6e6",
                marginRight: 0,
              }}
            />
          </Col>
          <Col
            span={18}
            style={{ display: "flex", alignItems: "center" }}
          >
            <TextArea
              autoSize
              value={item?.text}
              disabled={!isEditing}
              style={{ width: "auto" }}
              onChange={(e) => handleInputChange(index, language, e.target.value)}
            />
          </Col>
        </Row>
      ) : (
        <Row justify="start" style={{ width: "100%" }}>
          <Col
            span={18}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <TextArea
              autoSize
              value={item?.text}
              disabled={!isEditing}
              style={{ width: "auto" }}
              onChange={(e) => handleInputChange(index, language, e.target.value)}
            />
          </Col>
          <Col
            span={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar size={48} icon={<UserOutlined />} style={{ backgroundColor: "#e6e6e6" }}/>
          </Col>
        </Row>
      )}
    </List.Item>
  );
};

export default ConversationListItem;
