import { useContext } from "react";
import { Layout, Button, Space } from "antd";
import { UserContext } from "../contexts/UserContext";
import { NavigationContext } from "contexts/NavigationContext";

const { Header } = Layout;

const AppBar = () => {
  const { user, logout } = useContext(UserContext);
  const { handleNavigate } = useContext(NavigationContext);
  const path = window.location.pathname;

  return (
    <Header style={{ borderBottom: "1px solid #D8D8D8", marginBottom: "2em" }}>
      <div
        id="appbar"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* Left Section */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="/images/logo_img.png"
            alt="Logo"
            style={{ height: "30px", marginRight: "10px" }}
          />
        </div>

        {/* Middle Section */}
        <Space className="middle-section">
          <Button type="link" onClick={() => handleNavigate("/translations")}>
            {path.startsWith("/translations") ? (
              <b
                style={{
                  borderBottom: "2px solid #2D3648",
                  paddingBottom: "2px",
                  color: "#2D3648",
                }}
              >
                Traducció instantània
              </b>
            ) : (
              "Traducció instantània"
            )}
          </Button>
          <Button
            type="link"
            onClick={() => handleNavigate("/saved-translations")}
          >
            {path === "/saved-translations" ? (
              <b
                style={{
                  borderBottom: "2px solid #2D3648",
                  paddingBottom: "2px",
                  color: "#2D3648",
                }}
              >
                Traduccions guardades
              </b>
            ) : (
              "Traduccions guardades"
            )}
          </Button>
        </Space>

        {/* Right Section */}
        <Space>
          <span>{user && user.username}</span>
          <Button type="default" onClick={logout}>
            Logout
          </Button>
        </Space>
      </div>
    </Header>
  );
};

export default AppBar;
