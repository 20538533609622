import axios, { AxiosRequestConfig } from "axios";

const BASE_URL = process.env.REACT_APP_DEPINT_BACKEND_API;

enum ContentType {
  JSON = "application/json",
  FORM = "application/x-www-form-urlencoded",
  OCTET_STREAM = "application/octet-stream",
}
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    "Content-Type": ContentType.JSON,
  },
});

const apiCall = async (
  method: string,
  endpoint: string,
  data: any = null,
  contentType: ContentType = ContentType.JSON, 
  blob: boolean = false
) => {
  const url = `${BASE_URL}${endpoint}`;
  const token = localStorage.getItem("access_token");

  const config: AxiosRequestConfig = {
    method,
    url,
    data,
    headers: {
      "Content-Type": contentType,
      Authorization: `Bearer ${token}`,
    },
    ...(blob && { responseType: 'json' }),
  };

  try {
    const response = await axiosInstance(config);

    if (!response) return;

    if (response.status !== 200) {
      throw new Error(`API call failed: ${response.statusText}`);
    }

    return blob ? response : response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export default apiCall;
export { ContentType };
