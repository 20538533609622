import { useContext, useEffect, useCallback } from "react";
import { Routes, Route } from "react-router-dom";
import { NavigationContext } from "./contexts/NavigationContext";
import { UserContext } from "./contexts/UserContext";
import { checkUser } from "./utils/apiMethods";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import Layout from "components/Layout";
import Translations from "./pages/Translations";
import SavedTranslations from "./pages/SavedTranslations";
import NotFound from "./pages/NotFound";
import Conversation from "pages/Conversation";

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(UserContext);
  const { handleNavigate } = useContext(NavigationContext);

  useEffect(() => {
    if (!user) {
      handleNavigate("/login");
    }
  }, [user, handleNavigate]);

  return user ? children : null;
};

const App = () => {
  const { setUser } = useContext(UserContext);
  const { currentPath } = useContext(NavigationContext);
  const { handleNavigate, navigateToLastPath } = useContext(NavigationContext);

  const checkUserAuthentication = useCallback(async () => {
    try {
      const response = await checkUser();
      if (response.status_code === 200) {
        navigateToLastPath(["/login"]);
      } else {
        throw new Error("User not authenticated");
      }
    } catch (error) {
      setUser(null);
      handleNavigate("/login");
    }
  }, [setUser, navigateToLastPath, handleNavigate]);

  useEffect(() => {
    if (currentPath !== "/login") {
      checkUserAuthentication();
    }
  }, [currentPath, checkUserAuthentication]);

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <ProtectedRoute>
              <Layout>
                <HomePage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/translations"
          element={
            <ProtectedRoute>
              <Layout>
                <Translations />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/translations/new-conversation"
          element={
            <ProtectedRoute>
              <Layout>
                <Conversation />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/saved-translations"
          element={
            <ProtectedRoute>
              <Layout>
                <SavedTranslations />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="*"
          element={
            <Layout>
              <NotFound />
            </Layout>
          }
        />
      </Routes>
    </>
  );
};

export default App;
