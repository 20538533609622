import { Layout as AntLayout } from "antd";
import AppBar from "./Header";

const { Content } = AntLayout;

const Layout = ({ children }) => {
  return (
    <AntLayout>
      <AppBar />
      <Content>{children}</Content>
    </AntLayout>
  );
};

export default Layout;
