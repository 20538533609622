import callApi, { ContentType } from "./callApi";


const checkUser = async () => {
    return await callApi("GET", "auth/check");
};

const loginUser = async (username: string, password: string) => {
    return await callApi(
      "POST",
      "auth/login",
      { username, password },
      ContentType.FORM
    );
};

const logoutUser = async () => {
    return await callApi("POST", "auth/logout");
};

const getSavedConversations = async () => {
    return await callApi("GET", "conversations");
}

const saveConversation = async (conversation: any) => {
    return await callApi("POST", "conversations", conversation);
}

const downloadConversation = async (formData: any) => {
    try {
        const fileContentBase64 = await callApi("POST", "conversations/download", formData, ContentType.FORM, true);
  
        const contentDisposition = fileContentBase64.headers.get('content-disposition');
        let filename = 'download';
        if (contentDisposition) {
            filename = contentDisposition.split('filename=')[1];
        }

        try {
            const decodedContent = atob(fileContentBase64.data.message);

            const byteNumbers = new Array(decodedContent.length);
            for (let i = 0; i < decodedContent.length; i++) {
                byteNumbers[i] = decodedContent.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/octet-stream' });

            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', filename);

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        }
        catch (error) {
            console.log("ERROR:", error);
        }

    } catch (error) {
      console.error("Error downloading the file: ", error);
    }
  }

export { checkUser, logoutUser, loginUser, saveConversation, getSavedConversations, downloadConversation };
