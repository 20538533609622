import React from 'react';
import { ConfigProvider } from 'antd'
import { ProConfigProvider } from '@ant-design/pro-provider'
import esES from 'antd/locale/es_ES'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { BrowserRouter as Router } from "react-router-dom";

import Routes from './Routes'
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { UserProvider } from './contexts/UserContext';
import { NavigationProvider } from './contexts/NavigationContext';

const fallbackRender = ({ error }: FallbackProps) => {
	return (
		<div role="alert">
			<p>Something went wrong:</p>
			<pre style={{ color: 'red' }}>{error.message}</pre>
		</div>
	)
}

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={fallbackRender}>
      <ConfigProvider
        locale={esES}
        theme={{
          token: {
            fontFamily: "Helvetica",
            colorPrimary: "#003A70",
            fontWeightStrong: 400,
            colorInfo: "#014593",
            borderRadius: 8,
          },
          components: {
            Layout: {
              colorBgHeader: "#fff",
              colorBgLayout: "#fff",
              colorBgBody: "#fff",
            },
            Menu: {
              fontSize: 16,
            },
          },
        }}
      >
        <ProConfigProvider>
          <Router>
            <UserProvider>
              <NavigationProvider>
                <Routes />
              </NavigationProvider>
            </UserProvider>
          </Router>
        </ProConfigProvider>
      </ConfigProvider>
    </ErrorBoundary>
  );
};

export default App;