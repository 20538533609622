// NavigationContext.js
import React, { createContext, useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const NavigationContext = createContext({
  handleNavigate: (path, values) => {},
  navigateToLastPath: () => {},
  confirmNavigation: () => {},
});

const NavigationProvider = ({ children }) => {
  const navigate = useNavigate();
  const [lastPath, setLastPath] = useState(null);

  useEffect(() => {
    return () => {
      console.log("Navigation Context: cleanup");
    };
  }, []);

  const handleNavigate = useCallback(
    (path, values) => {
      const currentPath = window.location.pathname;
      navigate(path, { state: values });
      setLastPath(currentPath);
    },
    [navigate]
  );

  const navigateToLastPath = useCallback(
    (except = []) => {
      if (except && except.indexOf(lastPath) !== -1) {
        return;
      }
      handleNavigate(lastPath);
    },
    [handleNavigate, lastPath]
  );

  const values = useMemo(
    () => ({
      handleNavigate,
      navigateToLastPath,
      currentPath: window.location.pathname,
    }),
    []
  );

  return (
    <NavigationContext.Provider value={values}>
      {children}
    </NavigationContext.Provider>
  );
};

export { NavigationContext, NavigationProvider };
