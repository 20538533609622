import { useContext } from "react";
import { Layout, Form, Input, Button, Row, Col, message } from "antd";
import { UserContext } from "../contexts/UserContext";
import { loginUser } from "../utils/apiMethods";
import { NavigationContext } from "../contexts/NavigationContext";
import Footer from "components/Footer";

const LoginPage = () => {
  const { setUser } = useContext(UserContext);
  const { handleNavigate } = useContext(NavigationContext);
  const { Content } = Layout;

  const onFinish = async (values: any) => {

    try {
      await loginUser(values.username, values.password)
        .then((response) => {
          const user = response.data.user;
          setUser({
            ...user,
            name: `${user.name} ${user.surname}`,
          });
          const token = response.data.token;
          localStorage.setItem("access_token", token);
          handleNavigate("/translations");
        })
        .catch((error) => {
          message.error("Usuari o contrasenya incorrectes");
        });
    } catch (error) {
      message.error("Usuari o contrasenya incorrectes");
    }
  };

  return (
    <div id="loginPage">
      <div className="loginContainer">
        <Row className="d-flex justify-content-center w-100">
          <Col
            span={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              backgroundColor: "#003A70",
            }}
          >
            <img
              src="/images/mossos_img.svg"
              style={{ width: "35vw"}}
              alt="Mossos d'Esquadra"
            />
            <img
              src="/images/gencat_img.svg"
              className="gencat-img"
              alt="Generalitat de Catalunya"
            />
          </Col>
          <Col span={12} className="login-form">
            <Content
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <div style={{ textAlign: "center", width: 350 }}>
                <span className="title">Benvingut/da</span>
                <Form
                  name="login"
                  onFinish={onFinish}
                  initialValues={{ username: "", password: "" }}
                  style={{ width: '100%', marginTop: 16 }}
                  layout="vertical"
                >
                  <Form.Item
                    label="Usuari/ària"
                    name="username"
                    style={{ textAlign: "left" }}
                    rules={[
                      {
                        required: true,
                        message: "Introduir nom d'usuari/ària",
                      },
                    ]}
                  >
                    <Input placeholder="Introduir nom d'usuari/ària" />
                  </Form.Item>

                  <Form.Item
                    label="Contrasenya"
                    name="password"
                    style={{ textAlign: "left" }}
                    rules={[
                      {
                        required: true,
                        message: "Introduir contrasenya",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Introduir contrasenya" />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-button"
                    >
                      Inicia sessió
                    </Button>
                  </Form.Item>
                </Form>
                <img
                  src="/images/departament_interior_img.jpeg"
                  style={{ height: '10vh' }}
                  alt="Departament d'Interior"
                />
                <img
                  src="/images/centre_tele_img.png"
                  style={{ height: '3.5vh' }}
                  alt="Centre de Telecomunicacions i Tecnologies de la Informació"
                />
              </div>
            </Content>
            <Footer />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LoginPage;
