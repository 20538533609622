import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row, Tag, message, List, Button } from "antd";
import moment from "moment";
import { AudioOutlined, PauseOutlined, UndoOutlined } from "@ant-design/icons";
import SpeechToTranslation from "utils/speechToTranslation";
import { saveConversation } from "utils/apiMethods";
import { NavigationContext } from "contexts/NavigationContext";
import ConversationListItem from "components/ConversationLitsItem";

const Conversation: React.FC = () => {
  const [startTime, setStartTime] = useState<moment.Moment | null>(null);
  const [totalDuration, setTotalDuration] = useState<moment.Duration | null>(null);
  const [isFinished, setIsFinished] = useState(false);
  const [socket, setSocket] = useState<any | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [language1Block, setLanguage1Block] = useState<any>(null);
  const [language2Block, setLanguage2Block] = useState<any>(null);
  const [elapsedTime, setElapsedTime] = useState("00:00");
  const [isEditing, setIsEditing] = useState(false);
  const [speakerTurn, setSpeakerTurn] = useState(1);
  const [language1Conversation, setLanguage1Conversation] = useState<any>(null);
  const [language2Conversation, setLanguage2Conversation] = useState<any>(null);
  const [undoState, setUndoState] = useState<any>(false);
  const [nextState, setNextState] = useState<any>(false);
  const [startTimeBlock, setStartTimeBlock] = useState<any>(null);
  
  const { handleNavigate } = useContext(NavigationContext);

  const getMessage = () => {
    let currentSpeaker = speakerTurn === 1 ? participant1 : participant2;
    let currentLanguage = speakerTurn === 1 ? language1 : language2;
    if (!isRecording) {
      return `Orador ${currentSpeaker} prem el micròfon per iniciar la gravació en ${currentLanguage}`;
    } else {
      return `Escoltant l’orador ${currentSpeaker} en ${currentLanguage}`;
    }
  };

  useEffect(() => {
    let interval: any = null;
    
    if (!isEditing && isRecording) {
      interval = setInterval(() => {
        if (startTime) {
          const currentTime = moment();
          const duration = moment.duration(currentTime.diff(startTime));
          const total = duration.add(totalDuration)
          setTotalDuration(total);
          setElapsedTime(moment.utc(total.asMilliseconds()).format("mm:ss"));
        }
      }, 1000);
    } else if (isEditing && interval) {
      clearInterval(interval);
    }
    
    return () => clearInterval(interval);
  }, [isEditing, isRecording, startTime]);

  const location = useLocation();
  const { formValues } = location.state || {};

  const handleErrors = (errorMessage: string) => {
    message.error(errorMessage);
  };

  const updateBlockText = (block: any[], blockIndex: number, index: number, newText: string) => {
    if (blockIndex === index) {
      return block.map(item => ({ ...item, text: newText }));
    }
    return block;
  };

  const handleInputChange = (index: number, language: string, newText: string) => {
    const updateConversation = (prevData: any) => {
      const safePrevData = prevData || [];
      return safePrevData.map((block: any[], blockIndex: number) => updateBlockText(block, blockIndex, index, newText));
    };
  
    if (language === "language1") {
      setLanguage1Conversation(updateConversation);
    } else {
      setLanguage2Conversation(updateConversation);
    }
  };

  const updateTranslatedData = (newData: any) => {
    if (!newData) return;
    console.log("New data received", newData);
    if (!newData["language_1"] || !newData["language_2"]) {
      return;
    }
    setLanguage1Block(newData?.language_1?.text ?? []);

    setLanguage2Block(newData?.language_2?.text ?? []);
  };

  // Extract relevant data from formValues
  const { translationName, participant1, language1, participant2, language2 } =
    formValues || {};

  useEffect(() => {
    // Connect to the WebSocket server
    if (language1 && language2) {
      setSocket(
        new SpeechToTranslation(
          updateTranslatedData,
          { speakerTurn, language1, language2 },
          handleErrors
        )
      );
    }
  }, [language1, language2]);

  if (!formValues) {
    // Handle the case where formValues are not available
    handleNavigate("/translations")
    return <div>No form values provided</div>;
  }

  const startRecording = async () => {
    setStartTimeBlock(elapsedTime);
    setNextState(false);
    setUndoState(false);
    setLanguage1Block(null);
    setLanguage2Block(null);
    setIsFinished(false);
    if (!isFinished) {
      console.log("Recording started");
      setStartTime(moment());

      if (!socket) {
        return;
      }
      socket.updateInfo({ speakerTurn, language1, language2 });
      try {
        socket.start();
        setIsRecording(true);
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
    }
  };

  const stopRecording = () => {
    if (!socket) {
      return;
    }
    socket.close();
    addBlockText();
    setIsRecording(false);
    setUndoState(true);
    setNextState(true);
  };

  const finishRecording = () => {
    if (!socket) {
      return;
    }
    socket.close();
    setIsFinished(true);
    setUndoState(false);
    setNextState(false);
  }

  const handleSaveConversation = async () => {
    console.log("Saving translation");
    const dataToSave = parseDataToSave();
    await saveConversation(dataToSave)
      .then(() => {
        handleNavigate("/saved-translations")
      })
      .catch((error) => {
        message.error("Error guardant la traducció");
      });
  };

  const parseDataToSave = () => {
    return {
      conversationName: translationName,
      participant1,
      participant2,
      language1: language1Conversation.flat(),
      language2: language2Conversation.flat(),
      language1Code: language1,
      language2Code: language2,
      totalTime: elapsedTime,
    };
  };

  const getElapsedTime = () => {
    return elapsedTime;
  };

  const addBlockText = () => {
    setLanguage1Conversation((prevData: any) => {
      const safePrevData = prevData || [];
      const updated1Block = language1Block.map(block => ({
        ...block,
        start: startTimeBlock,
        end: elapsedTime
      }));
      const newData = [...safePrevData, updated1Block];
      return newData;
    });
  
    setLanguage2Conversation((prevData: any) => {
      const safePrevData = prevData || [];
      const updated2Block = language2Block.map(block => ({
        ...block,
        start: startTimeBlock,
        end: elapsedTime
      }));
      const newData = [...safePrevData, updated2Block];
      return newData;
    });
    setLanguage1Block(null);
    setLanguage2Block(null);
  }

  const removeBlockText = () => {
    setLanguage1Conversation((prevData: any) => {
      const safePrevData = prevData || [];
      const newData = [...safePrevData.slice(0, -1)];
      return newData;
    });
  
    setLanguage2Conversation((prevData: any) => {
      const safePrevData = prevData || [];
      const newData = [...safePrevData.slice(0, -1)];
      return newData;
    });
    setLanguage1Block(null);
    setLanguage2Block(null);
  }

  const nextSpeakerTurn = () => {
    setNextState(false);
    setUndoState(false);
    setSpeakerTurn((prevTurn) => (prevTurn === 1 ? 2 : 1));
  };

  const undoConversationBlock = () => {
    if (language1Conversation?.length >= 1) {
      const lastBlock = language1Conversation[language1Conversation.length - 1];
      const elapsedTimeStr = lastBlock[0].start;
      
      const [minutes, seconds] = elapsedTimeStr.split(':').map(Number);
      const elapsedTimeDuration = moment.duration({ minutes, seconds });
      if (totalDuration) {
        setTotalDuration(elapsedTimeDuration);
        setElapsedTime(elapsedTimeStr);
      }
    }
    removeBlockText();
    setUndoState(false);
  };

  return (
    <>
      <div
        className="header"
        style={{ borderBottom: "1px solid #F4F4F4", paddingBottom: "1em"}}
      >
        <div className="container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Tag>{participant1}</Tag>
              <Tag>{language1}</Tag>
            </div>
            <div>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  marginBottom: "0",
                }}
              >
                Conversa {translationName}
              </p>
              <div className="conversation-recorder text-center">
                <span className="elapsed-time">{getElapsedTime()}</span>
              </div>
            </div>
            <div>
              <Tag>{participant2}</Tag>
              <Tag>{language2}</Tag>
            </div>
          </div>
        </div>
      </div>
      <div className="conversation-container">
        <Row gutter={[32, 32]} style={{ minHeight: "66vh", maxWidth: "100%", marginInline: "0" }}>
          <Col span={12}>
            {language1Conversation !== null && language1Conversation.map((item: any, index: number) => (
              <List
                key={index}
                style={{ padding: "0em 3em" }}
                dataSource={item}
                renderItem={(item: any, i: number) => (
                  <ConversationListItem
                    item={item}
                    index={index}
                    isEditing={isEditing}
                    handleInputChange={handleInputChange}
                    language="language1"
                  />
                )}
              />
            ))}
            {language1Block !== null && (
              <List
                style={{ padding: "0em 3em" }}
                dataSource={language1Block}
                renderItem={(item: any, index: number) => (
                  <ConversationListItem
                    item={item}
                    index={index}
                    isEditing={false}
                    handleInputChange={handleInputChange}
                    language="language1"
                  />
                )}
              />
            )}
          </Col>
          <Col span={12} style={{ background: "#F7F7F7" }}>
            {/* Map language2Conversation */}
            {language2Conversation !== null && language2Conversation.map((item: any, index: number) => (
              <List
                key={index}
                style={{ padding: "0em 3em" }}
                bordered={false}
                dataSource={item}
                renderItem={(item: any, i: number) => (
                  <ConversationListItem
                    item={item}
                    index={index}
                    isEditing={isEditing}
                    handleInputChange={handleInputChange}
                    language="language2"
                  />
                )}
              />
            ))}
            {language2Block !== null && (
              <List
                style={{ padding: "0em 3em" }}
                bordered={false}
                dataSource={language2Block}
                renderItem={(item: any, index: number) => (
                  <ConversationListItem
                    item={item}
                    index={index}
                    isEditing={false}
                    handleInputChange={handleInputChange}
                    language="language2"
                  />
                )}
              />
            )}
          </Col>
        </Row>
        <Row
          gutter={[32, 32]}
          style={{
            padding: "1em 0",
            borderTop: "1px solid #F4F4F4",
            maxWidth: "84%",
            margin: "0 auto",
          }}
        >
          <Col span={8}>
            {/* Left Section */}
          </Col>
          <Col span={8}>
            <div className="conversation-buttons-container">
              <Button
                className={"conversation-button next-undo-buttons"}
                icon={<UndoOutlined />}
                disabled={!undoState}
                onClick={undoConversationBlock}
              />
              <Button
                className={"conversation-button record-button"}
                icon={!isRecording ? <AudioOutlined style={{ fontSize: '24px' }}/> : <PauseOutlined style={{ fontSize: '24px' }}/>}
                disabled={isFinished || (!language1Block && !language2Block && isRecording)}
                onClick={!isRecording ? startRecording : stopRecording}
              />
              <Button
                className={"conversation-button next-undo-buttons"}
                disabled={!nextState}
                onClick={nextSpeakerTurn}
              >
                <img
                  alt="next"
                  src="/images/white_next_button.png"
                  style={{ height: '30px'}}
                />
              </Button>
            </div>
            <span
              className="record-button-label"
            >
              {getMessage()}
            </span>
          </Col>
          <Col span={8}>
            {/* Right Section */}
            <div
              className="conversation-recorder"
              style={{ textAlign: "right" }}
            >
              {(language1Conversation?.length > 0 && language2Conversation?.length > 0) && (
                <div className="finish-buttons">
                  {!isRecording && isFinished && <Button style={{ marginRight: '10px'}} onClick={() => setIsEditing(true)} disabled={isEditing}>
                    {"Editar"}
                  </Button>}
                  <Button
                    onClick={
                      !isRecording && isFinished ? handleSaveConversation : finishRecording
                    }
                    type={!isRecording ? "primary" : "default"}
                    disabled={isRecording}
                  >
                    {(() => {
                      if (!isRecording && isFinished) {
                        if (isEditing) {
                          return "Guardar Canvis";
                        } else {
                          return "Guardar";
                        }
                      } else {
                        return "Finalitzar";
                      }
                    })()}
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Conversation;
