import React, { useState, useEffect } from "react";
import { message, Row, Col, Tag, Typography, Button, Modal, Form, Checkbox, Radio } from "antd";
import { getSavedConversations, downloadConversation } from "../utils/apiMethods";
import { DownloadOutlined, FileTextOutlined } from "@ant-design/icons";
import moment from "moment";

interface Translation {
  id: string;
  conversationName: string;
  date: string;
  language1Code: string;
  language2Code: string;
}

const SavedConversations = () => {
  const [savedConversations, setSavedConversations] = useState<Translation[]>(
    []
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [translation, setTranslation] = useState<Translation>({ id: "", conversationName: "", date: "", language1Code: "", language2Code: "" });
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { Text } = Typography;

  useEffect(() => {
    setLoading(false);
    retrieveSavedConversations();
  }, []);

  const showModal = (translation: any) => {
    setTranslation(translation);
    form.resetFields();
    setLoading(false);
    setIsModalOpen(true);
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const formData = {
        ...values,
        id: translation.id
      };
      await downloadConversation(formData);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error al validar el formulario:', error);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const retrieveSavedConversations = async () => {
    await getSavedConversations()
      .then((response) => {
        const sortedData = response.data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        setSavedConversations(sortedData);
      })
      .catch((error) => {
        message.error("Error al carregar les traduccions guardades");
      });
  };

  return (
    <>
    {savedConversations.length > 0 ?
      <div className="container">
        <Row gutter={[32, 32]}>
          {savedConversations.map((translation: Translation) => (
            <Col span={6} key={translation.conversationName}>
            <Button style={{ border: '0px', position: "absolute", right: '24px', top: '8px', zIndex: '1', boxShadow: 'none', color: '#00000073'}} 
              icon={<DownloadOutlined style={{ fontSize: '24px' }} />} size="large" onClick={() => showModal(translation)} />
              <div className="card p-4">
                <Text style={{ fontWeight: 500, color: "#2C2C2C", fontSize: 16 }}>
                  {translation.conversationName}
                </Text>
                <Text style={{ color: "#667085" }}>
                  {moment(translation.date).format("DD MMM YYYY")}
                </Text>
                <div className="mt-2">
                  <Tag>{translation.language1Code}</Tag>
                  <Tag>{translation.language2Code}</Tag>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      <Modal 
        title={<h2 style={{ fontSize: '24px', marginBottom: '20px', marginTop: '30px' }}>Descarrega't la traducció</h2>}
        open={isModalOpen} 
        onCancel={handleCancel}
        footer={[
          <div key="footer" style={{ textAlign: 'left' }}>
            <Button key="submit" style={{boxShadow: 'none'}} type="primary" onClick={handleDownload} loading={loading}>Descarregar</Button>
            <Button key="back" style={{border: '0', boxShadow: 'none'}} onClick={handleCancel}>Cancelar</Button>
          </div>
        ]}
        width={400}
      >
      <Form form={form}>
        <div style={{ marginBottom: '30px' }}>
          <div style={{ marginBottom: '14px' }}>Formats</div>
          <Form.Item 
            name="format"
            rules={[{ required: true, message: 'Si us plau, seleccioneu un format.' }]}
            >
            <Radio.Group style={{ display: 'block' }}>
              <Radio value="PDF">PDF</Radio>
              <Radio value="WORD">WORD</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div style={{ marginBottom: '30px' }}>
          <div style={{ marginBottom: '14px' }}>Idiomes</div>
          <Form.Item 
            name="languages"
            rules={[{ required: false, message: 'Si us plau, seleccioneu almenys un idioma.' }]}
            >
            <Checkbox.Group style={{ display: 'block' }} key={translation.id}>
              <Checkbox value={translation.language1Code}>{translation.language1Code}</Checkbox>
              <Checkbox value={translation.language2Code}>{translation.language2Code}</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </div>
      </Form>
      </Modal>
      </div>
    : 
      <div style={{ marginTop: '200px'}} className="d-flex justify-content-center align-items-center flex-column" > 
        <FileTextOutlined className="mb-4" style={{ fontSize: '85px', color: '#64748B' }}/>
        <div style={{ fontSize: '22px' }}>No existeixen traduccions guardades</div>
        <div style={{ fontSize: '16px', color: '#64748B'}}>Crea una nova traducció des de la pestanya Traducció instantània.</div>
      </div>}
    </>
  );
};

export default SavedConversations;
