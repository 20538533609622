import { Typography } from 'antd';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { Link } = Typography;

  return (
    <div className="footer-container">
      <p className="footer-text" style={{textAlign: "center"}}>
        Developed by{" "}
        <Link
          href="https://www.minsait.com"
          color="inherit"
          style={{ fontWeight: "bold", fontSize: 14 }}
        >
          Minsait
        </Link>
        {" and "}
        <Link
          href="https://omnios.ai/"
          color="inherit"
          style={{ fontWeight: "bold", fontSize: 14 }}
        >
          Omnios
          {/* Omnios Cognitive Solutions, S.L. */}
        </Link>{" "}
        © {currentYear}
      </p>
    </div>
  );
}

export default Footer;
