const DEFAULT_MAX_SPEECH_TIME = null;
const DEFAULT_CONNECTING_TIMEOUT = 8000;
const CHUNK_DURATION = 1000;

const LANGUAGE_NAME_TO_CODE  = {
  "Anglès": "en",
  "Angles": "en",
  "Català": "ca",
  "Catala": "ca",
  "Àrab": "ar",
  "Arab": "ar",
  "Urdu": "ur",
  "Castellà": "es",
  "Castella": "es"
}

const SPEAKER_CODE = {
  1: 0,
  2: 1
}

class SpeechToTranslation {
  constructor(
    onTranscriptionReceive,
    info,
    handleErrors,
    maxSpeechTime = DEFAULT_MAX_SPEECH_TIME,
    connectingTimeoutMillis = DEFAULT_CONNECTING_TIMEOUT,
    startMessage = "START"
  ) {
    this._onTranscriptionReceive = onTranscriptionReceive;
    this._webSocket = null;
    this._mediaRecorder = null;
    this._handleErrors = handleErrors;
    this._startMessage = startMessage;
    this._info = info;
    this._connectingTimeoutMillis = connectingTimeoutMillis;
  }

  start() {
    this.setupSocket();
    this.setupMediaRecorder();
  }

  close() {
    if (this._mediaRecorder && this._mediaRecorder.state !== "inactive") {
      this._stopMediaRecorder();
    }
    this._cancelConnectingTimeout();
    if (this._webSocket) {
      console.log("Closing WebSocket.");
      this._webSocket.close();
    }
  }

  async updateInfo(newInfo) {
    this._info = newInfo;
  }

  isStreaming() {
    return this.isWebSocketOpen() && this.isRecording() ? true : false;
  }

  isWebSocketOpen() {
    return this._webSocket && this._webSocket.readyState === WebSocket.OPEN
      ? true
      : false;
  }

  isRecording() {
    return this._mediaRecorder && this._mediaRecorder.state === "recording"
      ? true
      : false;
  }

  async setupMediaRecorder() {
    let stream;
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
    } catch (e) {
      console.error("No microphone allowed.");
      this._handleErrors("FORM.MICROPHONE.ERROR");
      return;
    }

    const options = MediaRecorder.isTypeSupported("audio/webm")
      ? { mimeType: "audio/webm" }
      : {};

    this._mediaRecorder = new MediaRecorder(stream, options);

    this._mediaRecorder.addEventListener("dataavailable", (e) => {
      if (e.currentTarget.state === "inactive") return;

      switch (this._webSocket.readyState) {
        case WebSocket.OPEN:
          this._webSocket.send(e.data);
          break;
        case WebSocket.CLOSING:
          console.debug("WebSocket is closing.");
          break;
        case WebSocket.CLOSED:
          console.log("Stopping media recorder.");
          this._stopMediaRecorder();
          console.error("WebSocket is not open");
          break;
        default:
          console.error(
            "Unknown WebSocket state: ",
            this._webSocket.readyState
          );
          break;
      }
    });

    this._mediaRecorder.addEventListener("stop", () => {
      console.log("MediaRecorder stopped");
    });

    this._mediaRecorder.start(CHUNK_DURATION);
  }

  setupSocket() {
    console.log("Setting up WebSocket. Info: ", this._info);

    this._webSocket = new WebSocket(process.env.REACT_APP_DEPINT_WEBSOCKET_URL
        + "?api_key=" + process.env.REACT_APP_DEPINT_API_KEY);
    this._startConnectingTimeout();
     
    const jsonToSend = {
      speaker: SPEAKER_CODE[this._info.speakerTurn],
      language: LANGUAGE_NAME_TO_CODE[this._info.speakerTurn === 1 ? this._info.language1 : this._info.language2],
      translate_to: LANGUAGE_NAME_TO_CODE[this._info.speakerTurn === 1 ? this._info.language2 : this._info.language1],
    };

    this._webSocket.onopen = () => {
      console.log("WebSocket is open now.");
      this._webSocket.send(JSON.stringify(jsonToSend));
      this._cancelConnectingTimeout();
    };

    this._webSocket.onmessage = (event) => {
      try {
        const jsonData = JSON.parse(event.data);
        this._onDataReceive(jsonData);
      } catch {
        this._onDataReceive(event.data);
      }
    };

    this._webSocket.onerror = (errorEvent) => {
      console.error("WebSocket error:", errorEvent);
      this._handleErrors("Error al conectar amb el servidor. Reintentant...");
      this.start();
    };

    this._webSocket.onclose = (event) => {
      console.log("WebSocket is closed now. Code:", event.code);
      if (event.code === 1011 || event.code === 1006) {
        console.log("Unexpected close. Trying to reconnect...");
        this._handleErrors("Error al conectar amb el servidor. Reintentant...");
        this.start();
      }
    
      if (this._mediaRecorder && this._mediaRecorder.state !== "inactive") {
        this._stopMediaRecorder();
      }
    };
  }

  _onDataReceive(data) {
    console.log("Data received:", data);
    this._onTranscriptionReceive(data);
  }

  _startConnectingTimeout() {
    this._cancelConnectingTimeout();
    this._connectingTimeout = setTimeout(() => {
      console.error("Could not connect to the server.");
      this.close();
    }, this._connectingTimeoutMillis);
  }

  _stopMediaRecorder() {
    this._mediaRecorder.stream.getTracks().forEach((track) => track.stop());
  }

  _cancelConnectingTimeout() {
    if (this._connectingTimeout) clearTimeout(this._connectingTimeout);
    else console.warn("No connecting timeout to cancel.");
  }
}

export default SpeechToTranslation;
